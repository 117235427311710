@mixin flex-column-cAlign-cJustify {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-column-cJustify-cAlign {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-cAlign-cJustify {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-cJustify-cAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin container-padding {
  padding: 0px 20px;
  
  @media(min-width: $maxMobileWidth) {
    max-width: 480px;
    margin: 0 auto;
  }

  @media(min-width: $maxTabletWidth) {
    max-width: 670px;
    margin: 0 auto;
  }
}

@mixin img-p-container {
  @include container-padding;
  text-align: center;
  h1 {
    font-weight: 700;
    line-height: 48px;
    color: black;
    margin-top: 5px;
  }
  img {
    max-width: 208px;
  }
}

@mixin flex-cAlign {
  display: flex;
  align-items: center;
}

@mixin flex-cJustify {
  display: flex;
  justify-content: center;
}

@mixin basic-odd-even-transition {
  & > :nth-child(odd):not(& > div):not(&__divider)
  {
    transform: translateX(-110vw);
    opacity: 0;
    transition: transform .5s ease, opacity .2s ease;
  }
  & > :nth-child(even):not(& > div) {
      transform: translateX(110vw);
      opacity: 0;
      transition: transform .5s ease, opacity 1.2s ease;
  }

  & > div > :nth-child(odd) {
    transform: translateX(-110vw);
    opacity: 0;
    transition: transform .5s ease, opacity .2s ease;
  }
  & > div > :nth-child(even) {
      transform: translateX(110vw);
      opacity: 0;
      transition: transform .5s ease, opacity 1.2s ease;
  }

  &__animation {
      &-enter-done {
          :nth-child(odd):not(.carousel-slider *):not(.mc__tree2):not(.mc__tree),
          :nth-child(even):not(.carousel-slider *):not(.mc__tree2):not(.mc__tree) {
              transform: translateX(0) !important;
              opacity: 1 !important;
          }
      }
  }
}
