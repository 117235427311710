.customButton {
	button {
		&[disabled] {
			opacity: .2;
		}
		cursor: pointer;
		text-align: center;
		padding: 16px 100px;
		background: $green;
    border: none;
    outline: none;
		color: white;
		font-family: Space-Mono-Bold;
		display: flex;
		justify-content: center;
    align-items: center;
    margin: 0 auto;
		font-size: 16px;
		img {
			margin-right: 12px;
			max-width: 24px
		}
	}
}