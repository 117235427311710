.customCarousel {
  width: 100%;
  &__item {
    &__img {
      width: 100%;
      height: 30vh;
      max-height: 240px;
      background-size: cover !important;
    }
    p {
      margin-bottom: 35px;
      overflow: auto;
    }
    button {
      padding: 16px;
      width: 80%;
    }
    padding-bottom: 85px;
  }
  .dot {
    &.selected {
      background: $skyBlue !important;
    }
    width: 15px !important;
    height: 15px !important;
    background: black !important;
    margin: 0 18px !important;
  }

  @media(min-width: $maxMobileWidth) {
    &__item {
      padding: 0px 10px 85px 10px;
      p {
        margin-bottom: 0px;
        height: 120px;
      }
    }
    .carousel-slider {
      min-height: 530px;
    }
    position: absolute;
    width: 100vw;
    left: $leftOffsetNonMobile;
  }

  @media (min-width: $maxTabletWidth) {
    left: $leftOffsetNonTablet;
  }
}