.solo {
	position: fixed;
	&--still {
		position: relative;
	}
	opacity: 0;
	z-index: -1;
	transition: opacity .2s ease, z-index 1s ease;
	
	&__p {
		opacity: 0;
		z-index: -1;
		position: fixed;
    bottom: 40px;
    right: 10px;
    width: 267px;
    background: white;
    padding: 10px 15px;
    border-radius: 17px;
    box-shadow: -2px 3px 20px 2px #00000066;
		transition: opacity .2s ease, z-index 1s ease;
	}
	&.show, &__p.show {
		opacity: 1;
		z-index: 10;
		transition: opacity .2s ease, z-index 1s ease;
	}
}

