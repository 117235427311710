.clouds {
	max-width: unset !important;
	padding: 0px !important;
	svg {
		width: 100%;
	}
	@media(min-width: $maxTabletWidth) {
		height: 310px;
		svg {
			&:first-of-type {
				left: -30vw;
				top: 200px;
			}
			&:nth-of-type(2) {
				left: 23vw;
				top: 0px;
			}
			&:last-of-type {
				right: -30vw;
				top: 200px;
			}
			position: absolute;
			width: 890px;
		}
	}
}