.customForm {
  h1 {
    margin-bottom: 0px;
    font-size: 40px;
  }
  h2 {
    margin-top: 0px;
    color: white;
    font-size: 32px;;
  }
  form {
    ::placeholder {
      color: #B8B8B8;
      font-family: Space-Grotesk-Regular;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: #B8B8B8;
     font-family: Space-Grotesk-Regular;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
     color: #B8B8B8;
     font-family: Space-Grotesk-Regular;
    }

    label {
      color: white;
      text-align: left;
      margin-left: 10px;
      font-family: Space-Mono-Bold;
      font-size: 20px;
    }
    input[type="text"], textarea {
      padding: 12px;
    }
    input {
      margin-bottom: 20px;
    }
    display: flex;
    flex-direction: column;
  }

  button {
    width: 100%;
    margin-top: 50px;
  }
}