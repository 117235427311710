.mikeyriver__nav {
    transition: all .2s ease;
    opacity: 0;
    
    ul li {
        &:nth-child(1) {
            transform: translateY(-200px);
            transition: all, .2s ease;
        }
        &:nth-child(2) {
            transform: translateY(-200px);
            transition: all .25s ease;
        }
        &:nth-child(3) {
            transform: translateY(-200px);
            transition: all, .30s ease;
        }
        
        transition: opacity .1s ease;
        opacity: 0;
    }

    &__animation {
        &-enter-done {
            opacity: 1;
            ul li {
                transform: translateY(0px) !important;
                opacity: 1;
            }
        }

    }
}

.mikeyriver__basic {
    @include basic-odd-even-transition;
    
    svg {
        transform: translateX(0) !important;
        transform: translatey(-220px) !important;
    }
    // override some animations
    &__animation {
        &-enter-done {
            // transform of trees manually set in mc.scss
            :nth-child(odd):not(.mc__tree2):not(.mc__tree),
            :nth-child(even):not(.mc__tree2):not(.mc__tree) {
                transform: translateX(0) !important;
                opacity: 1 !important;
            }
        }
    }
}


.mikeyriver__music {
    @include basic-odd-even-transition;
}

.mikeyriver__resume {
    @include basic-odd-even-transition;

    // do not allow these to animate
    .customCurve, .customCurve svg {
        transform: translateX(0) !important;
        opacity: 1 !important;
    }
}