.customCurve {
  &.custom-shape-divider-bottom-1664115935 {
    position: absolute;
    top: -159px;
    left: $leftOffsetNonMobile;
    width: 100vw;
    overflow: hidden;
    line-height: 0;
  }

  &.custom-shape-divider-bottom-1664115935 svg {
      position: relative;
      display: block;
      width: calc(100% + 1.3px);
      height: 150px;
  }

  &.custom-shape-divider-bottom-1664115935 .shape-fill {
      fill: #F4FCFF;
  }

  /** For mobile devices **/
  @media (max-width: $maxMobileWidth) {
    &.custom-shape-divider-bottom-1664115935 {
      left: 0px;
      top: -90px;
    }

    &.custom-shape-divider-bottom-1664115935 svg {
      width: calc(100% + 1.3px);
      height: 81px;
    }
  }

  /** For desktop devices **/
  @media (min-width: $maxTabletWidth) {
    &.custom-shape-divider-bottom-1664115935 {
      left: $leftOffsetNonTablet;
      top: -90px;
    }

    &.custom-shape-divider-bottom-1664115935 svg {
      width: calc(100% + 1.3px);
      height: 81px;
    }
  }
}