.mc {
	position: absolute;
	transform: none !important;
	// 540 = 500 maxwidth + 20 padding L + 20 padding R
	left: $leftOffset;
	max-width: unset;
	width: 100vw;

	img {
		position: absolute;
	}

	&__tree {
		left: -170px;
    height: 450px;
		top: -345px;
    z-index: 1;
    width: auto;
    max-width: unset !important;
		transform: translateX(0) !important;
    opacity: 1 !important;
	}

	&__tree2 {
		@extend .mc__tree;
		left: unset;
		right: -170px;
	}

	&__grass {
		width: 100vw;
		max-width: unset !important;
		left: 0px;
	}

	@media(min-width: $maxMobileWidth) {
		left: $leftOffsetNonMobile;
		width: 100%;
		max-width: unset !important;
		height: 100%;
		left: 0px;
		top: 0px;

		&__tree {
			top: unset;
			bottom: 0px;
			left: 0px;
			right: 0px;
			margin: 0px auto;
			transform: translateX(-411px) !important;
		}
	
		&__tree2 {
			margin: 0px auto;
			top: unset;
			bottom: 0px;
			left: 0px;
			right: 0px;
			transform: translateX(411px) !important;
		}

		&__grass {
			width: auto;
			max-width: unset !important;
			bottom: -90px;
			left: 0px;
			right: 0px;
			margin: 0px auto;
		}

		&__grassBlock {
			bottom: 0px;
			width: 100px;
			margin: 115px;
		}
	}

	@media(min-width: 1280px) {
		&__tree {
			transform: translateX(-661px) !important;
		}
	
		&__tree2 {
			transform: translateX(661px) !important;
		}

		&__grass {
			bottom: -310px;
		}
	}
}